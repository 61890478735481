div.wrapper {
    gap: 5px;
    display: flex;
    cursor: pointer;
}

.modal {
    text-align: center;
    & a {
        text-decoration: underline;
    }
}


