.banner {
	--gap: 15px;
	
	font-size: xx-large;
	margin: 15px;
	padding: 5px;
	z-index: 1;
	display: flex;
	flex-direction: row;
	gap: var(--gap);
	position: relative;
	text-align: center;
}
.bg {
    border-top: 2px solid rgba(0,0,0, .75);
	border-bottom: 2px solid rgba(0,0,0, .75);
	background-color: rgba(0, 128, 0, .75);
    mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 5%,
        rgba(0, 0, 0, .5),
        rgba(0, 0, 0, 0) 95%
    );
	z-index: 0;

	/* Fill parent container */
    position: absolute;
    left: 0px; 
	right: 0px;
	top: 0px;
	bottom: 0px;
}
.banner > div {
	display: flex;
}
.heightFix {
	width: 0px;
	overflow: hidden;
}

.titleContainer {
	position: relative;
	align-content: center;
	min-width: 275px;
	flex: 2;
	text-shadow: 0px 0px 8px var(--bgColor);
}
.titleTransition {
	transition: opacity 1.5s ease;
	position: absolute;
	left: 0px;
	right: 0px;
}
.titleTransitionOn {
	opacity: 1;
	transition-delay: 1.5s;
}
.titleTransitionOff {
	opacity: 0;
}

.right, .left {
	min-width: 30px;
	display: flex;
	flex: 1;
	align-items: center;
	gap: var(--gap);
	z-index: 1;
}
.right {
	justify-content: end;
	& span {
		opacity: .5;
	}
}

.navButton {
	cursor: pointer !important;
	justify-content: center;
	display: flex;
	&:hover {
		opacity: 1;
	}
}

.readerModeToggle {
	
}

@media (max-width:992px) {
	.bg {
		background-color: rgba(0, 128, 0, 0.70);
	}
    .banner {
		font-size: x-large;
		font-weight: 600
	}
}

@media (max-width:500px) {
	.bg {
		background-color: rgba(0, 128, 0, 0.70);
		left: -50%;
	}
	.banner {
		text-align: left;
		font-size: large;
	}
	.left {
		display: none !important;
	}
}

@media (max-height:500px) and (orientation:landscape) {
	.banner {
		display: none;
	}
}