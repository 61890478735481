div.wrapper {
    position: absolute;
    z-index: 1;
    top:0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: color-mix(in srgb, var(--bgColor) 66%, transparent);
    cursor: pointer;
}

.modal {
    position: relative;
    z-index: 2;
    max-width: 400px;
    display: flex;
    align-self: center;
    margin: 20px;
    background-color: var(--bgColor);

    border: 2px solid var(--color);
    border-radius: 5px;
    padding: 25px 40px;
    line-height: 32px; 

    text-align: justify;
    cursor: auto;
}

.closeButton {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: .5;
    display: flex;
    &:hover {
        opacity: 1;
    }
}