div.wrapper,
.childContainer
{
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-self: center;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
}
.spinner {
    align-self: center;
    z-index: 100;
    position: absolute !important;

    & span {
        box-shadow:inset 0px 0px 0px 1px var(--color);
    }
}

.childContainer {
    animation-duration: .5s;
}