.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	background-repeat: no-repeat;

}
.bgContainer {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	&.color {
		z-index: -1;
	}
	&.image {
		z-index: -2;
	}
}

:global(body.darkMode) .image {
	filter: var(--darkModeImgFilters);
}

.routerContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: hidden;
}

.footer {
	display: flex;
	justify-content: space-evenly;
}

.copyright {
	background-color: rgba(255, 255, 255, .75);
	color: rgba(0,0,0, .75);
	text-shadow: 0, 0, 8px, white;
	border-radius: 10px 10px 0px 0px;
	padding: 5px;
	padding-right: 7px;
	z-index:1;
}

:global(body.darkMode) .copyright {
	color: rgba(255,255,255, .75);
	background-color: rgba(0, 0, 0, .15) !important;
}

.tooltip {
	z-index: 2;
}