body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Support Flex layout. */
html,
body,
div#root {
	height: 100%;
	width: 100%;
}
a {
    color: var(--color);
    text-decoration: none;
}

/* Printer styles */
@media not print {
	.onlyPrint {
		display: none !important;
	}
}
@media print {
	.noPrint {
		display: none !important;
	}
    * {
        overflow-y: unset !important;
        height: unset !important;
    }
    .pagebreak { 
        break-after: always; 
    }
}
/* Book download styles */
.noIndent>p {
    text-indent: 0px !important;
}
.getGoneRapBoph {
    text-align: left;
    color: maroon;
}
.getGoneRapStarbust {
    text-align: right;
    color: navy;
}
.getGoneRapAudience {
    text-align: center;
    color: green;
}
body.darkMode {
    & .getGoneRapBoph {
        color: color-mix(in srgb, maroon 50%, var(--color));
    }
    & .getGoneRapStarbust {
        color: color-mix(in srgb, navy 50%, var(--color));
    }
    & .getGoneRapAudience {
        color: color-mix(in srgb, green 50%, var(--color));
    }
}

/* Fades */
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fadeIn {
    animation: 2s ease 0s 1 fadeInAnimation;
    &.hide {
		opacity: 0;
    }
    &:not(.hide) {
		opacity: 1;
    }
}

/* Common Styles & Vars */
body {
    --bgColor: rgba(255,255,255,1);
    --color: black;
    --darkModeImgFilters: invert(1) grayscale(.5) brightness(.5);
    color: var(--color);
    &.darkMode {
        --bgColor: rgba(0,0,0,1);
        --color: rgba(192, 192, 192, 1);
    }
}
.luciferText {
    margin: 0px;
    font-size: 36px;
    font-weight: 500;
    text-shadow: 0px 0px 5px var(--bgColor), 0px 0px 5px var(--bgColor), 0px 0px 10px var(--color);
    text-align: center;
    border-radius: 5px;
    flex: 0 1 auto;
}

.fadeContainer {
	flex-flow: column;
	overflow-y: auto;  
    mask-image: linear-gradient( /* Works on FF, fails on Chrome profile page */
        to bottom,
        black 95%,
        transparent 100%
    );
}